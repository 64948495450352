import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Title, Box, Text, Button } from "./Core";
import { device } from "../utils";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
// import { FormattedDate } from "react-intl";

const ContentCardStyled = styled(Box)`
  width: 100%;
  transition: 0.4s;
  overflow: hidden;

  .img-container {
    position: relative;
    z-index: 1;
    img {
      border-radius: 8px 8px 0 0;
      max-width: 100%;
    }
    @media ${device.md} {
      height: 260px;
    }
  }

  &:hover {
    transform: translateY(-20px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`;

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;
  ${ContentCardStyled}:hover & {
    opacity: 1;
    margin-top: 0px;
  }
  button {
    font-size: 16px;
    padding: 0.5rem 1.25rem;
    @media ${device.sm} {
      font-size: 18px;
      padding: 0.75rem 1.5rem;
    }
    @media ${device.lg} {
      padding: 0.85rem 1.75rem;
    }
  }
`;

const TextContent = styled(Box)`
  position: relative;
  z-index: 1;
  transition: 0.4s;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 23px;
  padding-bottom: 21px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.sm} {
    padding-top: 43px;
    padding-bottom: 41px;
    padding-left: 38px;
    padding-right: 38px;
  }
`;

const Shape = styled(Box)`
  background: initial;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  bottom: 98%;
  @media ${device.sm} {
    bottom: 99%;
  }
  svg {
    width: 100%;
    path {
      fill: ${({ theme, bg }) => theme.colors[bg]};
      transition: 0.4s;
    }
  }
`;

// const PreTitle = styled(Text)`
//   font-size: 16px;
//   font-weight: 300;
//   letter-spacing: -0.5px;
//   line-height: 28px;
//   margin-bottom: 22px;
// `;

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @media ${device.sm} {
    font-size: 34px;
    font-weight: 700;
  }
`;

const ContentCard = ({
  isDark = true,
  bg = "secondary",
  img,
  imgAlt,
  date = "",
  title = "",
  children = "",
  cta,
  url,
}) => {
  return (
    <Link to={url}>
      <ContentCardStyled>
        <div className="img-container">
          <PreviewCompatibleImage
            imageInfo={{
              image: img,
              alt: imgAlt,
            }}
          />
          <BtnContainer>
            <Button>{cta}</Button>
          </BtnContainer>
        </div>
        <TextContent bg={bg}>
          <Shape bg={bg}>
            <svg height="22" viewBox="0 0 540 22">
              <g>
                <g>
                  <path d="M0 0s233.088 28.458 539.999 0c306.91-28.458 0 22 0 22H.06"></path>
                </g>
              </g>
            </svg>
          </Shape>
          {/* <PreTitle color={isDark ? "lightShade" : "darkShade"}>
            <FormattedDate
              value={new Date(date)}
              month="long"
              day="numeric"
              year="numeric"
            />
          </PreTitle> */}
          <TitleStyled color={isDark ? "light" : "dark"}>{title}</TitleStyled>
          <Text color={isDark ? "lightShade" : "darkShade"}>{children}</Text>
        </TextContent>
      </ContentCardStyled>
    </Link>
  );
};

export default ContentCard;
