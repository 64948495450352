import React from "react";
import { Col } from "react-bootstrap";
import ContentCard from "./ContentCard";
import { FormattedMessage } from "react-intl";
import { Text, Box } from "./Core";

const JobRoll = (props) => {
  const { edges: jobs } = props.jobs;

  return (
    <>
      {jobs.length > 0 ? (
        jobs.map(({ node: post }) => (
          <Col lg="6" className="mb-5" key={post.fields.slug}>
            <ContentCard
              img={post.frontmatter.featuredimage}
              imgAlt={`featured image post ${post.frontmatter.title}`}
              date={post.frontmatter.date}
              title={post.frontmatter.title}
              url={post.fields.slug}
              cta={props.cta}
            >
              {post.frontmatter.description}
            </ContentCard>
          </Col>
        ))
      ) : (
        <Box
          className="d-flex justify-content-center w-100"
          border="1px solid #EAE9F2;"
          borderRadius={8}
          p={4}
          mb={4}
        >
          <Text>
            <FormattedMessage
              id="jobs.emptyState"
              defaultMessage="We currently don't have any open positions, but we're always open for a chat"
            />
          </Text>
        </Box>
      )}
    </>
  );
};

export default JobRoll;
