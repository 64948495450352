import React from "react";
import { graphql } from "gatsby";
import Jobs from "../../components/Jobs";

const JobsNL = (props) => <Jobs {...props} />;

export default JobsNL;

export const pageQuery = graphql`
  query JobsNLQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "job-post" }, draft: { ne: true } }
        fields: { langKey: { regex: "/(nl|any)/" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
