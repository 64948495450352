import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Section, Title, Text } from "./Core";
import PageWrapper from "./PageWrapper";
import JobRoll from "./JobRoll";

const Jobs = (props) => {
  const intl = useIntl();

  return (
    <PageWrapper footerDark>
      <Section className="pb-0">
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="8">
              <Title>
                <FormattedMessage
                  id="jobs.heading"
                  defaultMessage="Job offers"
                />
              </Title>
              <Text>
                <FormattedMessage
                  id="jobs.subheading"
                  defaultMessage="Join Tournify and get the ball rolling"
                />
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>
      <div className="pt-5"></div>
      <Container>
        <Row>
          <JobRoll
            cta={intl.formatMessage({
              id: "jobs.cta",
              defaultMessage: "jobs blog",
            })}
            jobs={props.data.allMarkdownRemark}
          />
        </Row>
      </Container>
      <div className="pt-3"></div>
    </PageWrapper>
  );
};

export default Jobs;
